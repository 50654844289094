import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import ArticlesList from '../components/ArticlesList';

const TagTemplate = ({ data, pageContext }) => {
  const articles = data.allContentfulArticle.nodes;
  return (
    <Layout>
      <main className='page'>
        <h1>{pageContext.tag}</h1>
        <div className='tag-recipes'>
          <ArticlesList articles={articles}></ArticlesList>
        </div>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query GetArticleByTag($tag: String) {
    allContentfulArticle(
      sort: { fields: title, order: ASC }
      filter: { tags: { eq: $tag } }
    ) {
      nodes {
        featured
        createdWhen
        createdBy
        id
        order
        tags
        title
        image {
          gatsbyImageData
          title
        }
      }
    }
  }
`;

export default TagTemplate;
