import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import slugify from 'slugify'

const ArticlesList = ({ articles = [] }) => {
  return (
    <div className='recipes-list'>
      {articles.map((article) => {
        const { id, title, image, createdWhen } = article
        const pathToImage = getImage(image)
        const slug = slugify(title, { lower: true })
        return (
          <Link key={id} to={`/${slug}/`} className='recipe'>
            <GatsbyImage
              image={pathToImage}
              className='recipe-img'
              alt={title}
            />
            <p className='recipe-when'>{createdWhen}</p>
            <h5>{title}</h5>
          </Link>
        )
      })}
    </div>
  )
}

export default ArticlesList
